/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
// import makeStyles from '@mui/styles/makeStyles';
// // import withStyles from '@mui/styles/withStyles';
import {styled} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Menu from '@mui/material/Menu';
import {hasErrors} from 'support/helpers/front';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import GradeSharpIcon from '@mui/icons-material/GradeSharp';
import ListItemText from '@mui/material/ListItemText';
import * as _ from 'underscore';
import {PROFILE_ACTION} from 'app/actions/profileAction';
import Grid from '@mui/material/Grid';
import {AUTH_LOGOUT} from 'app/constants/actionTypes';
import Button from '@mui/material/Button';
import whitelogo from 'app/assets/whitelogo.svg';
import Cookies from 'js-cookie';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import Img from 'react-cool-img';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {EdgeTrigger} from '@mui-treasury/layout';
import Badge from '@mui/material/Badge';
// import styled from 'styled-components';
import Link from '@mui/material/Link';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {
  HTTP_COUNT_NOTIFICATIONS,
  HTTP_MARKREAD_NOTIFICATION,
} from 'app/services/httpClient/gather.service';
import NotificationTable from 'app/pages/notifications/notification-table';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LeadUsageChart from 'app/components/LeadUsageChart';

// const SidebarTrigger = getSidebarTrigger(styled);

// const StyledMenu = withStyles({
//   paper: {
//     border: '1px solid #d3d4d5',
//     marginTop: '5px',
//     width: '200px',
//     marginLeft: '16px',
//     zIndex: 1000,
//   },
// })((props) => (
//   <Menu
//     elevation={1}
//     // getContentAnchorEl={null}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'right',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'right',
//     }}
//     {...props}
//   />
// ));

// const NotificationMenu = withStyles({
//   paper: {
//     border: '1px solid #d3d4d5',
//     marginTop: '5px',
//     width: '400px',
//     marginLeft: '16px',
//     zIndex: 1000,
//   },
// })((props) => (
//   <Menu
//     elevation={1}
//     // getContentAnchorEl={null}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'right',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'right',
//     }}
//     {...props}
//   />
// ));

const StyledMenu = styled((props) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
    PaperProps={{
      sx: {
        border: '1px solid #d3d4d5',
        marginTop: '5px',
        width: '200px',
        marginLeft: '16px',
        zIndex: 1000,
      },
    }}
  />
))({});

const NotificationMenu = styled((props) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
    PaperProps={{
      sx: {
        border: '1px solid #d3d4d5',
        marginTop: '5px',
        width: '400px',
        marginLeft: '16px',
        zIndex: 1000,
      },
    }}
  />
))({});

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     height: '58px',
//   },
//   toolbar: {
//     paddingRight: 24, // keep right padding when drawer closed
//     backgroundColor: '#003671',
//     width: '100%',
//     height: '58px',
//     minHeight: '58px',
//   },
//   logo: {
//     maxWidth: 200,
//     paddingTop: '5px',
//     // marginTop: '12px',
//     marginLeft: '-4px',
//   },
//   toolbarIcon: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     padding: '0 8px',
//     ...theme.mixins.toolbar,
//     // backgroundColor: 'white',
//   },
//   menuButton: {
//     marginRight: 36,
//   },
//   menuButtonHidden: {
//     display: 'none',
//   },
//   title: {
//     flexGrow: 1,
//   },
//   appBarSpacer: theme.mixins.toolbar,
//   content: {
//     flexGrow: 1,
//     height: '100vh',
//     overflow: 'auto',
//   },
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   },
//   paper: {
//     padding: theme.spacing(2),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
//     elevation: 1,
//   },
//   fixedHeight: {
//     height: 240,
//   },
// }));

export const DashNavbar = function () {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [logout, setLogout] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  function handleLogout(props) {
    setNotifications(0);
    setLogout(true);
    dispatch({
      type: AUTH_LOGOUT,
    });
  }

  const UserProfile = () => {
    const user = useSelector((state) => state.PROFILE_REDUCER.Profile);
    return user;
  };
  const Auth = () => {
    const auth = useSelector((state) => state.AUTH_REDUCER.Auth);
    return auth;
  };
  const ErrorState = () => {
    const error = useSelector((state) => state.ERROR_REDUCER.Errors);
    return error;
  };

  const profile = UserProfile();
  const auth = Auth();
  const error = ErrorState();

  useEffect(() => {
    const DISPATCH_PROFILE = () => {
      dispatch(PROFILE_ACTION(auth.token));
    };
    DISPATCH_PROFILE();
  }, []);

  useEffect(() => {
    if (Cookies.get('_token') === null) {
      setNotifications(0);
      setLogout(true);
      handleLogout();
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const UpdateCount = (props) => {
    getNotificationCount(profile._id)
      .then((result) => {
        // Handle the result
      })
      .catch((error) => console.log('NOTIFICATION COUNT ERROR', error));
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const classes = useStyles();

  async function getNotificationCount(profileId) {
    if (!profileId || logout) return;
  
    setLoading(true);
  
    try {
      const response = await HTTP_COUNT_NOTIFICATIONS(profileId);
      const data = await response.json();
  
      // console.log('Notification Count API Response:', data);
  
      if (data[1] !== undefined) {
        setNotifications(data[1]);
      } else {
        setNotifications(0);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  }

  // async function getNotificationCount(pro) {
  //   setLoading(true);
  //   if (logout !== true) {
  //     if (pro === undefined) {
  //       setLoading(false);
  //       return false;
  //     } else {
  //       await HTTP_COUNT_NOTIFICATIONS(pro)
  //         .then((response) => response.json())
  //         .then((response) => {
  //           console.log('COUNT RESPONSE', response);
  //           if (response[1] === null) {
  //             setNotifications(0);
  //             setLoading(false);
  //           } else {
  //             setNotifications(response[1]);
  //             setLoading(false);
  //           }
  //         })
  //         .catch((error) => {
  //           // handle error
  //           console.log(error);
  //           setLoading(false);
  //         });
  //     }
  //   }
  // }

  const [url, setUrl] = React.useState('');

  useEffect(() => {
    if (profile?._id) {
      getNotificationCount(profile._id);
    }
  }, [profile?._id, location.pathname]);

  // useEffect(() => {
  //   if (
  //     (notifications === null && loading !== true && logout !== true) ||
  //     (typeof window !== 'undefined' && window.location.pathname !== url && logout !== true)
  //   ) {
  //     if (typeof window !== 'undefined') {
  //       setUrl(window.location.pathname);
  //     }
  //     getNotificationCount(profile._id).catch((error) =>
  //       console.log('NOTIFICATION COUNT ERROR', error)
  //     );
  //   }
  // }, []);

  // const Upgrade = function (props) {
  //   if (
  //     profile.subscription === 'prod_JJ9SxmKmWgiYTn' ||
  //     profile.subscription === 'prod_JJ9TZDgTn7XCrg' ||
  //     profile.subscription === process.env.REACT_APP_DEV_ENT_MONTHLY_PROD_ID ||
  //     profile.subscription === process.env.REACT_APP_DEV_ENT_ANNUAL_PROD_ID
  //   ) {
  //     return null;
  //   } else {
  //     return (
  //       <Button
  //         style={{
  //           textTransform: 'capitalize',
  //           fontSize: '1rem',
  //           minWidth: '116px',
  //           fontWeight: '600',
  //         }}
  //         onClick={() => {
  //           navigate({
  //             pathname: '/upgrade',
  //           });
  //         }}
  //         // color="white"
  //         variant="outlined"
  //         className="upgrade-button">
  //         Upgrade
  //       </Button>
  //     );
  //   }
  // };
  const Upgrade = function (props) {
    if (
      profile.subscription === 'prod_JJ9SxmKmWgiYTn' ||
      profile.subscription === 'prod_JJ9TZDgTn7XCrg' ||
      profile.subscription === process.env.REACT_APP_DEV_ENT_MONTHLY_PROD_ID ||
      profile.subscription === process.env.REACT_APP_DEV_ENT_ANNUAL_PROD_ID
    ) {
      return null;
    } else {
      return (
        <Button
          style={{
            textTransform: 'capitalize',
            fontSize: '1rem',
            minWidth: '96px',
            fontWeight: '600',
            position: 'relative',
            overflow: 'hidden',
          }}
          onClick={(e) => {
            const ripple = document.createElement('span');
            ripple.classList.add('ripple');
            const rect = e.target.getBoundingClientRect();
            ripple.style.left = `${e.clientX - rect.left}px`;
            ripple.style.top = `${e.clientY - rect.top}px`;
            e.target.appendChild(ripple);
            setTimeout(() => ripple.remove(), 600);
  
            navigate({
              pathname: '/credits',
            });
          }}
          variant="outlined"
          className="upgrade-button">
          Credits
        </Button>
      );
    }
  };
  

  return (
    <div className="dashroot">
      <CssBaseline />
      <Toolbar className="toolbar">
        <Hidden mdUp>
          {/* <SidebarTrigger /> */}
          <EdgeTrigger
            target={{anchor: 'left', field: 'open'}}
            style={{marginLeft: '-5px', paddingRight: '8px'}}>
            {(open, setOpen) => (
              <IconButton onClick={() => setOpen(!open)}>
                {open ? (
                  <CloseSharpIcon style={{color: 'white'}} />
                ) : (
                  <MenuSharpIcon style={{color: 'white'}} />
                )}
              </IconButton>
            )}
          </EdgeTrigger>
        </Hidden>
        <div
          onClick={() => {
            navigate({
              pathname: '/leadsearch',
            });
          }}
          style={{cursor: 'pointer'}}>
          <img
            // src="https://storage.googleapis.com/find-me-sales-bucket/assets/whitelogo.svg"
            src={whitelogo}
            width="200px"
            height="auto"
            alt="logo"
            className="logo"
          />
        </div>
        <Typography component="h1" variant="h6" color="inherit" noWrap className="title" />
        <Hidden smDown>
          <LeadUsageChart profile={profile} />
          <Upgrade />
          <div>
            <IconButton
              color="inherit"
              aria-controls="customized-menu"
              aria-haspopup="true"
              aria-label="Menu"
              className="nav-icon-dropdown"
              onClick={handleOpen}
              sx={{'& .MuiBadge-badge': {fontWeight: '700'}}}
              size="large">
              <Badge badgeContent={!loading ? notifications : 0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <NotificationMenu
              id="customized-menu"
              style={{marginTop: '18px', borderRadius: '0px 0px 4px 4px'}}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <p style={{paddingLeft: '16px'}}>
                    <b>Notifications</b>
                  </p>
                </Grid>
                <Grid item xs={9}>
                  <p style={{textAlign: 'right'}}>
                    <Link
                      color="primary"
                      onClick={() => {
                        const payload = {
                          owner: profile._id,
                          read: true,
                        };
                        HTTP_MARKREAD_NOTIFICATION(payload)
                          .then((response) => response.json())
                          .then((response) => {
                            setNotifications(0);
                          })
                          .catch((error) => {
                            // handle error
                            console.log(error);
                          });
                      }}
                      variant="body2"
                      style={{
                        cursor: 'pointer',
                        padding: '16px 8px 0px 8px',
                        textAlign: 'inherit',
                      }}>
                      Mark done
                    </Link>
                    |
                    <Link
                      color="primary"
                      onClick={() => {
                        handleClose();
                        navigate({
                          pathname: '/actions',
                        });
                      }}
                      variant="body2"
                      style={{
                        cursor: 'pointer',
                        padding: '16px 16px 0px 8px',
                        textAlign: 'inherit',
                      }}>
                      See all
                    </Link>
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <NotificationTable
                    profile={profile}
                    number={!loading ? notifications : 0}
                    close={handleClose}
                    UpdateCount={UpdateCount}
                  />
                </Grid>
              </Grid>
            </NotificationMenu>
          </div>

          <IconButton
            color="inherit"
            className="nav-icon"
            onClick={() => {
              navigate({
                pathname: '/settings',
              });
            }}
            size="large">
            <SettingsIcon />
          </IconButton>

          <IconButton
            color="inherit"
            className="nav-icon-end"
            onClick={() => {
              navigate({
                pathname: '/profile',
              });
            }}
            size="large">
            <AccountCircleOutlinedIcon />
          </IconButton>
        </Hidden>
        <Hidden smUp>
          <div>
            <IconButton
              color="inherit"
              aria-controls="customized-menu"
              aria-haspopup="true"
              className="nav-icon-end"
              onClick={handleClick}
              size="large">
              <MoreVertSharpIcon />
            </IconButton>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onClick={handleClose}>
              <MenuItem
                // className="highlightmenuitem"
                onClick={() => {
                  navigate({
                    pathname: '/upgrade',
                  });
                }}>
                <ListItemIcon className="listicons">
                  <GradeSharpIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Credits" />
              </MenuItem>
              <MenuItem
                // className="highlightmenuitem"
                onClick={() => {
                  navigate({
                    pathname: '/',
                  });
                }}>
                <ListItemIcon className="listicons">
                  <NotificationsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Notifications" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate({
                    pathname: '/settings',
                  });
                }}>
                <ListItemIcon className="listicons">
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate({
                    pathname: '/profile',
                  });
                }}>
                <ListItemIcon className="listicons">
                  <AccountCircleOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </MenuItem>
              <MenuItem>
                <ListItemIcon className="listicons">
                  <ExitToAppSharpIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText onClick={() => handleLogout()} primary="Logout" />
              </MenuItem>
            </StyledMenu>
          </div>
        </Hidden>
      </Toolbar>
    </div>
  );
};
