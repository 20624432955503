export const productFeatures = {
  Free: {
    Credits: 10,
    Discount: 0,
    NextDiscount: 24,
    NextTier: 'Starter',
    'Maximum Leads': 250,
    'Leadsearch Max Leads Per Page': 10,
    'Sales Automation Flows': 1,
    'Email templates': 1,
  },
  Starter: {
    Credits: 125,
    Discount: 24,
    NextDiscount: 33,
    NextTier: 'Pro',
    'Maximum Leads': 1000,
    'Leadsearch Max Leads Per Page': 25,
    'Sales Automation Flows': 3,
    'Email templates': 24,
  },
  Pro: {
    Credits: 400,
    Discount: 33,
    NextDiscount: 43,
    NextTier: 'Enterprise',
    'Maximum Leads': 5000,
    'Leadsearch Max Leads Per Page': 50,
    'Sales Automation Flows': 10,
    'Email templates': 33,
  },
  Enterprise: {
    Credits: 750,
    Discount: 43,
    NextDiscount: undefined,
    NextTier: undefined,
    'Maximum Leads': 'Unlimited',
    'Leadsearch Max Leads Per Page': 100,
    'Sales Automation Flows': 'Unlimited',
    'Email templates': 'Unlimited',
  },
  // If you eventually have brand-new tiers, add them here
};

// 2) This function translates environment-specific Stripe product IDs
//    into the plan names above. For annual vs. monthly, we simply
//    return the same name (e.g. “Starter”), so they share the same discount.
export function GetSub(productId) {
  // console.log('productId', productId, process.env.REACT_APP_DEV_STARTER_MONTHLY_PROD_ID);
  switch (productId) {
    case 'free':
      return 'Free';

    // Starter monthly
    case process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_STARTER_MONTHLY_PROD_ID
      : 'prod_JIPeHHrDLJVeq6':
      return 'Starter';

    // Starter annual
    case process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_STARTER_ANNUAL_PROD_ID
      : 'prod_JJ9NtRl2zwBFMp':
      return 'Starter'; // Normalise annual to “Starter”

    // Pro monthly
    case process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_PRO_MONTHLY_PROD_ID
      : 'prod_JJ9PmoiMobH3Ox':
      return 'Pro';

    // Pro annual
    case process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_PRO_ANNUAL_PROD_ID
      : 'prod_JJ9Qjzd3LIC6TG':
      return 'Pro'; // Normalise annual to “Pro”

    // Enterprise monthly
    case process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_ENT_MONTHLY_PROD_ID
      : 'prod_JJ9SxmKmWgiYTn':
      return 'Enterprise';

    // Enterprise annual
    case process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_ENT_ANNUAL_PROD_ID
      : 'prod_JJ9TZDgTn7XCrg':
      return 'Enterprise'; // Normalise annual to “Enterprise”

    default:
      return null; // unknown product ID
  }
}

// 3) The helper that returns a feature limit (e.g. Credits, Discount) based on plan
export function getFeatureLimit(productId, feature) {
  // Convert “prod_….” or “free” to “Starter”, “Pro”, or “Free”
  const sub = GetSub(productId);
  // console.log('sub', sub);
  if (!sub) return null;


  return productFeatures[sub]?.[feature] ?? null;
}

// const productFeatures = {
//   Free: {
//     // Starter Monthly & Annually
//     Credits: 10,
//     Discount: 0,
//     'Maximum Leads': 250,
//     'Leadsearch Max Leads Per Page': 10,
//     'Sales Automation Flows': 1,
//     'Email templates': 1,
    
//     // ... other features ...
//   },
//   Starter: {
//     // Starter Monthly & Annually
//     Credits: 125,
//     Discount: 24,
//     'Maximum Leads': 1000,
//     'Leadsearch Max Leads Per Page': 25,
//     'Sales Automation Flows': 3,
//     'Email templates': 24,
//     // ... other features ...
//   },
//   Pro: {
//     // Pro Monthly & Annually
//     Credits: 400,
//     Discount: 33,
//     'Maximum Leads': 5000,
//     'Leadsearch Max Leads Per Page': 50,
//     'Sales Automation Flows': 10,
//     'Email templates': 33,
//     // ... other features ...
//   },
//   Enterprise: {
//     // Enterprise Monthly & Annually
//     Credits: 750,
//     Discount: 43,
//     'Maximum Leads': 'Unlimited',
//     'Leadsearch Max Leads Per Page': 100,
//     'Sales Automation Flows': 'Unlimited',
//     'Email templates': 'Unlimited',
//     // ... other features ...
//   },
//   // ... other products ...
// };

// const GetSub = function (product) {
//   // console.log('product', product, process.env.REACT_APP_DEV_STARTER_MONTHLY_PROD_ID);
//   switch (product) {
//     case 'free':
//       return 'Free';
//     case process.env.NODE_ENV === 'development'
//       ? process.env.REACT_APP_DEV_STARTER_MONTHLY_PROD_ID
//       : 'prod_JIPeHHrDLJVeq6':
//       return 'Starter';
//     case process.env.NODE_ENV === 'development'
//       ? process.env.REACT_APP_DEV_STARTER_ANNUAL_PROD_ID
//       : 'prod_JJ9NtRl2zwBFMp':
//       return 'Starter Annually';
//     case process.env.NODE_ENV === 'development'
//       ? process.env.REACT_APP_DEV_PRO_MONTHLY_PROD_ID
//       : 'prod_JJ9PmoiMobH3Ox':
//       return 'Pro';
//     case process.env.NODE_ENV === 'development'
//       ? process.env.REACT_APP_DEV_PRO_ANNUAL_PROD_ID
//       : 'prod_JJ9Qjzd3LIC6TG':
//       return 'Pro';
//     case process.env.NODE_ENV === 'development'
//       ? process.env.REACT_APP_DEV_ENT_MONTHLY_PROD_ID
//       : 'prod_JJ9SxmKmWgiYTn':
//       return 'Enterprise';
//     case process.env.NODE_ENV === 'development'
//       ? process.env.REACT_APP_DEV_ENT_ANNUAL_PROD_ID
//       : 'prod_JJ9TZDgTn7XCrg':
//       return 'Enterprise';
//     default:
//       return null;
//   }
// };

// export function getFeatureLimit(product, feature) {
//   // Determine the actual product ID based on the environment
//   const sub = GetSub(product);
//   // Return the feature limit
//   return productFeatures[sub]?.[feature] ?? null;
// }
