import React, {useEffect, useRef} from 'react';
import Chart from 'chart.js/auto';
import {Tooltip} from '@mui/material';
import {getFeatureLimit} from 'app/utils/getFeatureLimit';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PeopleIcon from '@mui/icons-material/People';
import GoogleIcon from '@mui/icons-material/Google';

function LeadUsageChart({profile}) {
  const canvasRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (!profile || !profile.subscription || profile.remaining_leads === undefined) return;

    let maxLeads = getFeatureLimit(profile.subscription, 'Credits');
    // Ensure maxLeads is at least 100 and rounds up if needed
    if (!maxLeads || maxLeads === 'Unlimited') {
      maxLeads =
        profile.remaining_leads > 100 ? Math.ceil(profile.remaining_leads / 100) * 100 : 100;
    }
    // If remaining leads exceed maxLeads, round maxLeads up to the nearest 100
    if (profile.remaining_leads > maxLeads) {
      maxLeads = Math.ceil(profile.remaining_leads / 100) * 100;
    }

    const remaining = Math.min(profile.remaining_leads, maxLeads);
    const used = maxLeads - remaining;
    const isLow = remaining <= 5;

    const ctx = canvasRef.current.getContext('2d');

    // Destroy existing chart before creating a new one
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // A custom plugin to draw a single white ring around the outer edge
    const outerRingPlugin = {
      id: 'outerRing',
      afterDraw: (chart) => {
        const {ctx, chartArea} = chart;
        const {left, right, top, bottom} = chartArea;
        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;
        // Find the radius from the chart area
        const radius = Math.min(right - left, bottom - top) / 2;

        ctx.save();
        ctx.strokeStyle = 'white'; // The ring colour
        ctx.lineWidth = 2; // Thickness of the ring
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.restore();
      },
    };

    chartInstanceRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [remaining, used],
            // White (or red if low) for remaining, blue for used
            backgroundColor: [isLow ? 'red' : 'white', '#003671'],
            borderWidth: 0, // No internal dividing line
          },
        ],
      },
      options: {
        rotation: 180, // Start from the bottom
        circumference: 360,
        cutout: '0%', // Full pie
        responsive: false,
        maintainAspectRatio: false,
        layout: {
          padding: 2,
        },
        plugins: {
          tooltip: {enabled: false},
        },
      },
      plugins: [outerRingPlugin], // Include our plugin
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [profile.remaining_leads, profile.credits, profile.subscription]);

  return (
    <Tooltip
      title={
        <>
          <strong style={{fontSize: '16px'}}>
            {profile.credits ? profile.remaining_leads + profile.credits : profile.remaining_leads}{' '}
            Credits Remaining
          </strong>
          <br />
          <em style={{fontSize: '12px'}}>Credits are used to:</em>
          <ul style={{paddingTop: '8px', paddingLeft: 2, margin: 0}}>
            <li style={{marginBottom: '8px', fontSize: '12px'}}>
              <AutoFixHighIcon style={{height: '14px', marginBottom: '-3px'}} /> Enrich a company
              for 1 Credit
            </li>
            <li style={{marginBottom: '8px', fontSize: '12px'}}>
              <PeopleIcon style={{height: '14px', marginBottom: '-3px'}} /> 1 People results page
              for 1 Credit
            </li>
            <li style={{marginBottom: '8px', fontSize: '12px'}}>
              <GoogleIcon style={{height: '14px', marginBottom: '-3px'}} /> 1 Google Maps results
              page for 1 Credit
            </li>
          </ul>
        </>
      }
      arrow
      style={{padding: '8px'}}
      placement="bottom">
      <canvas
        ref={canvasRef}
        width={40}
        height={40}
        style={{marginRight: 30, marginTop: -10, cursor: 'pointer'}}
      />
    </Tooltip>
  );
}

export default LeadUsageChart;
