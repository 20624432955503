import {
  AUTH_LOGIN,
  AUTH_REGISTER,
  CLEAR_ERRORS,
  AUTH_LOGOUT,
  VERIFY,
  AUTH_CONFIRM,
  RESET_LEARN_STATE,
} from 'app/constants/actionTypes';
import {
  HTTP_REQUEST_LOGIN,
  HTTP_REQUEST_REGISTER,
  HTTP_REQUEST_EMAIL_VERIFY,
  HTTP_REQUEST_EMAIL_CONFIRM,
} from 'app/services/httpClient/auth.service';
import {toast} from 'react-toastify';
import * as helpers from 'support/helpers/front';
import ReactGA from 'react-ga4';
import {v4 as uuidv4} from 'uuid';
import Cookies from 'js-cookie';

const {setActionType} = helpers;

/**
 * Login action.
 *
 * @export
 * @async
 * @function
 * @name LOGIN_ACTION
 * @returns {Function}
 */
export function LOGIN_ACTION(payload) {
  return async (dispatch, getState) => {
    try {
      const LOGIN_RESPONSE = await HTTP_REQUEST_LOGIN(payload);
      // Dispatch Action
      if (LOGIN_RESPONSE.status !== 206) {
        dispatch({
          type: setActionType(LOGIN_RESPONSE.status, AUTH_LOGIN),
          payload: await LOGIN_RESPONSE.json(),
        });
      }
      return LOGIN_RESPONSE;
    } catch (error) {
      console.error('Error handling login: ', error);
    }
  };
}

/**
 * Logout action.
 *
 * @export
 * @function
 * @name LOGOUT_ACTION
 * @returns {Function}
 */
export function LOGOUT_ACTION() {
  try {
    return async (dispatch, getState) => {
      // dispatch action.
      dispatch({
        type: setActionType(200, RESET_LEARN_STATE),
      });
      dispatch({
        type: AUTH_LOGOUT,
      });
    };
  } catch (error) {
    console.error('Error handling logout: ', error);
  }
}

export function REGISTER_ACTION(payload, authtype) {
  return async (dispatch, getState) => {
    payload.authtype = authtype;
    let gaEventSent = false; // Flag to track if GA event has been sent

    try {
      const REGISTER_RESPONSE = await HTTP_REQUEST_REGISTER(payload);
      if (REGISTER_RESPONSE.status === 400) {
        const resp = await REGISTER_RESPONSE.json();
        toast.error(resp.message);
      } else {
        if (authtype === 'Email') {
          toast.success(
            'Registration success!! Please verify your email (it may have gone to your junk folder)...'
          );
        } else if (authtype === 'Google' || authtype === 'Microsoft') {
          toast.success('Registration success! Just 2 more fields to go...');
        }

        if (!gaEventSent) {
          ReactGA.event({
            category: 'User',
            action: 'Trial',
          });
          gaEventSent = true; // Set the flag to true after sending the event
        }

        dispatch({
          type: setActionType(REGISTER_RESPONSE.status, AUTH_REGISTER),
          payload: await REGISTER_RESPONSE.json(),
        });
        // this.props.navigate('/success/?m=free-trial');
      }
    } catch (error) {
      console.error('Error handling register: ', error);
    }
  };
}

/**
 * 3d Secure Register action.
 *
 * @export
 * @function
 * @name SECURE_REGISTER_ACTION
 * @param payload
 * @returns {Function}
 */
export function SECURE_REGISTER_ACTION(payload) {
  return async (dispatch, getState) => {
    // Dispatch Action.
    dispatch({
      type: AUTH_REGISTER,
      payload,
    });
  };
}

/**
 * Verify action.
 *
 * @export
 * @function
 * @name VERIFY_ACTION
 * @param payload
 * @returns {Function}
 */
export function VERIFY_ACTION(payload) {
  return async (dispatch, getState) => {
    const VERIFY_RESPONSE = await HTTP_REQUEST_EMAIL_VERIFY(payload);
    // Dispatch Action.
    dispatch({
      type: setActionType(VERIFY_RESPONSE.status, VERIFY),
      payload: await VERIFY_RESPONSE.json(),
    });
  };
}

/**
 * Confirm action.
 *
 * @export
 * @function
 * @name CONFIRM_ACTION
 * @param payload
 * @returns {Function}
 */
export function CONFIRM_ACTION(payload) {
  return async (dispatch, getState) => {
    const CONFIRM_RESPONSE = await HTTP_REQUEST_EMAIL_CONFIRM(payload);
    // Dispatch Action.
    if (CONFIRM_RESPONSE.status === 200) {
      toast.success(
        'Your email has been confirmed successfully. We just need a few more bits of information before you start.'
      );
      dispatch({
        type: setActionType(CONFIRM_RESPONSE.status, AUTH_CONFIRM),
        payload: await CONFIRM_RESPONSE.json(),
      });
      return CONFIRM_RESPONSE;
    } else {
      toast.error('There was an issue confirming your email.');
      return CONFIRM_RESPONSE;
    }
  };
}

/**
 * Clear auth action.
 *
 * @export
 * @function
 * @name CLEAR_AUTH_ACTION
 * @returns {Function}
 */
export function CLEAR_AUTH_ACTION() {
  return async (dispatch, getState) => {
    try {
      // dispatch action.
      dispatch({
        type: CLEAR_ERRORS,
      });
    } catch (error) {
      console.error('Error handling clear auth: ', error);
    }
  };
}
