import {AUTH_LOGIN, AUTH_REGISTER, AUTH_LOGOUT, AUTH_CONFIRM} from 'app/constants/actionTypes';
import Cookies from 'js-cookie';

const initState = {
  Auth: {
    isAuthenticated: false,
    // Get token from localStorage.
    token: Cookies.get('_token'),
    errors: '',
  },
};

export function AuthReducer(state = initState, {type, payload}) {
  switch (type) {
    case AUTH_REGISTER:
      Cookies.set('_token', payload.response.data);
      return {
        ...state,
        Auth: {
          isAuthenticated: true,
          token: payload.response.data,
          errors: payload.response.errors,
        },
      };
    case AUTH_LOGIN:
      Cookies.set('_token', payload.response.data);
      // console.log('AUTH_LOGIN', payload.response.data);
      return {
        ...state,
        Auth: {
          isAuthenticated: true,
          token: payload.response.data,
          errors: payload.response.errors,
        },
      };
    case AUTH_CONFIRM:
      Cookies.set('_token', payload.response.data);
      // console.log('AUTH_CONFIRM', payload);
      return {
        ...state,
        Auth: {
          isAuthenticated: true,
          token: payload.response.data,
          errors: payload.response.errors,
        },
      };
    case AUTH_LOGOUT:
      // Remove token from localStorage.
      // console.log('AUTH LOGOUT');
      // Cookies.remove('persist:root');
      // Cookies.remove('fmschatid');
      // Cookies.remove('_token');
      [
        'persist:root',
        '_token',
        'fmschatid',
        'isOpen',
        'lead',
        'leadsearch',
        'leads',
        'addlead',
        'enrichlead',
        'template',
        'automation',
        'email',
      ].forEach(Cookies.remove);
      localStorage.removeItem('persist:root')
      return {
        ...state,
        Auth: {isAuthenticated: false, token: null},
      };
    default:
      return state;
  }
}
