/* eslint-disable react/function-component-definition */
import React, { Component, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export default function InfoTooltip(props) {
  return (
    <Tooltip title={props.text} interactive placement="right">
      <InfoOutlinedIcon
        sx={{
          '&:hover': {
            backgroundColor: '#f5f5f5',
            borderRadius: '24px',
            // paddingBottom: '2px',
            // color: '#f40057',
          },
        }}
        style={{
          backgroundColor: '#ffffff',
          borderRadius: '34px',
          zIndex: '9999',
          color: '#003671',
          fontSize: '16px',
          height: '12px',
          width: '12px',
          marginBottom: '5px',
          cursor: 'pointer',
          ...props.style,
        }}
      />
    </Tooltip>
  );
}
