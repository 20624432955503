import React, {createContext, useContext, useState} from 'react';
import CreditPurchaseModal from 'app/components/CreditPurchaseModal';

const CreditModalContext = createContext();

export default function CreditModalProvider({children}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [userPlan, setUserPlan] = useState('Free');

  const openModal = (plan) => {
    setUserPlan(plan);
    setModalOpen(true);
  };

  return (
    <CreditModalContext.Provider value={{openModal}}>
      {children}
      <CreditPurchaseModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        userPlan={userPlan}
      />
    </CreditModalContext.Provider>
  );
}

export const useCreditModal = () => useContext(CreditModalContext);
