import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import {useLocation} from 'react-router-dom';

let initialPageviewTracked = false; // Global flag to track initial pageview

// Helper: Check if running in a browser
const isBrowser = typeof window !== 'undefined';

// // Helper: Check for analytics consent
// const hasAnalyticsConsent = () => {
//   if (isBrowser && window.dataLayer) {
//     const consent = window.dataLayer.find((item) => item.event === 'consent_update');
//     console.log('consent', consent);
//     return consent?.analytics_storage === 'granted';
//   } else {
//     return false;
//   }
// };
const hasAnalyticsConsent = () => {
  if (isBrowser && window.dataLayer) {
    const consent = window.dataLayer.find((item) => item.event === 'consent_update');
    return consent?.analytics_storage === 'granted';
  }
  return false;
};

// Helper: Detect bots
function isProbablyBot(userAgent) {
  const botKeywords = ['bot', 'crawl', 'spider', 'curl', 'wget', 'slurp', 'mediapartners'];
  const ogDataFetchers = ['facebookexternalhit', 'facebot', 'pinterestbot'];

  const isKnownBot = botKeywords.some((keyword) => userAgent.toLowerCase().includes(keyword));
  const isOGDataFetcher = ogDataFetchers.some((keyword) =>
    userAgent.toLowerCase().includes(keyword)
  );

  return isKnownBot && !isOGDataFetcher;
}

// Class Component: Tracks initial pageview (SSR-compatible)
class GoogleAnalytics extends React.Component {
  componentDidMount() {
    if (
      isBrowser &&
      !initialPageviewTracked && // Only track if not already tracked
      !isProbablyBot(navigator.userAgent) &&
      hasAnalyticsConsent()
    ) {
      const {location} = this.props;
      const path = location.pathname + location.search;
      // console.log('CDM path', path, window.dataLayer);
      ReactGA.send({hitType: 'pageview', page: path});
      initialPageviewTracked = true; // Set flag to true after tracking
    }
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

// Functional Component: Tracks route changes on the client
function RouteTracker() {
  const location = useLocation();

  useEffect(() => {
    if (
      isBrowser &&
      (!initialPageviewTracked ||
        location.pathname + location.search !==
          window.location.pathname + window.location.search) &&
      !isProbablyBot(navigator.userAgent)
    ) {
      const path = location.pathname + location.search;

      // console.log('path HERE', path, window.dataLayer);
      // Track page views
      ReactGA.send({hitType: 'pageview', page: path});

      initialPageviewTracked = true;
    }
  }, [location.pathname, location.search]);

  return null;
}
// function RouteTracker() {
//   const location = useLocation();

//   useEffect(() => {
//     if (
//       isBrowser &&
//       (!initialPageviewTracked ||
//         location.pathname + location.search !==
//           window.location.pathname + window.location.search) &&
//       !isProbablyBot(navigator.userAgent)
//     ) {
//       const path = location.pathname + location.search;
//       ReactGA.send({hitType: 'pageview', page: path}); // Send pageview
//       initialPageviewTracked = true;
//     }
//   }, [location.pathname, location.search]);

//   return null;
// }

// // Initialise Analytics (only on the client)
// const init = (options = {}) => {
//   const trackingId = 'G-DTHTQM9V6V'; // Replace with your Google Analytics ID

//   if (isBrowser && !isProbablyBot(navigator.userAgent)) {
//     const gaOptions = hasAnalyticsConsent()
//       ? {} // Full tracking if consent is granted
//       : {anonymizeIp: true}; // Anonymise IP if no consent

//     ReactGA.initialize(trackingId, {
//       gaOptions,
//       ...options,
//     });
//   }
// };
const init = (options = {}) => {
  const trackingId = 'G-DTHTQM9V6V'; // Replace with your Google Analytics ID
  // console.log('init', trackingId, window.dataLayer);
  if (isBrowser && !isProbablyBot(navigator.userAgent)) {
    const gaOptions = hasAnalyticsConsent()
      ? {} // Full tracking if consent is granted
      : { anonymizeIp: true }; // Anonymise IP if no consent

    ReactGA.initialize(trackingId, {
      gaOptions,
      ...options,
    });
  }
};

// New function to reset and reinitialise ReactGA
const reInit = () => {
  if (isBrowser) {
    ReactGA.reset(); // Clear existing GA state
    init(); // Reinitialise with updated consent
  }
};

// Example Event Tracking (Purchase)
const Transaction = ({invoice: inv, sub}) => {
  if (isBrowser) {
    ReactGA.event({
      name: 'purchase',
      params: {
        currency: inv.currency,
        value: sub.revenue,
        items: [
          {
            item_id: inv.id,
            item_name: inv.name,
            item_category: inv.category,
            price: inv.price,
            quantity: 1,
          },
        ],
        transaction_id: sub.id,
      },
    });
  }
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
  reInit,
  Transaction,
};

// import React, { useEffect } from 'react';
// import ReactGA from 'react-ga4';
// import { useLocation } from 'react-router-dom';

// // Check if we're in a browser environment
// const isBrowser = typeof window !== 'undefined';

// const hasAnalyticsConsent = () => {
//   if (isBrowser && window.dataLayer) {
//     const consent = window.dataLayer.find((item) => item.event === 'consent_update');
//     return consent?.analytics_storage === 'granted';
//   }
//   return false;
// };

// function isProbablyBot(userAgent) {
//   const botKeywords = ['bot', 'crawl', 'spider', 'curl', 'wget', 'slurp', 'mediapartners'];
//   const ogDataFetchers = ['facebookexternalhit', 'facebot', 'pinterestbot'];

//   const isKnownBot = botKeywords.some((keyword) => userAgent.toLowerCase().includes(keyword));
//   const isOGDataFetcher = ogDataFetchers.some((keyword) => userAgent.toLowerCase().includes(keyword));

//   return isKnownBot && !isOGDataFetcher;
// }

// const RouteTracker = () => {
//   const location = useLocation();

//   useEffect(() => {
//     if (isBrowser && !isProbablyBot(navigator.userAgent) && hasAnalyticsConsent()) {
//       const path = location.pathname + location.search;
//       ReactGA.send({ hitType: 'pageview', page: path });
//     }
//   }, [location.pathname, location.search]);

//   return null;
// };

// const initAnalytics = (trackingId, options = {}) => {
//   if (isBrowser) {
//     const isGAEnabled = !!trackingId;

//     if (isGAEnabled && !isProbablyBot(navigator.userAgent) && hasAnalyticsConsent()) {
//       ReactGA.initialize(trackingId, { ...options });
//     }

//     return isGAEnabled;
//   }
//   return false; // Return false in non-browser environments
// };

// const Transaction = ({ invoice: inv, sub }) => {
//   if (isBrowser) {
//     ReactGA.event({
//       name: 'purchase',
//       params: {
//         currency: inv.currency,
//         value: sub.revenue,
//         items: [
//           {
//             item_id: inv.id,
//             item_name: inv.name,
//             item_category: inv.category,
//             price: inv.price,
//             quantity: 1,
//           },
//         ],
//         transaction_id: sub.id,
//       },
//     });
//   }
// };

// export default {
//   RouteTracker,
//   initAnalytics,
//   Transaction,
// };

// import React, {Component, useEffect} from 'react';
// import PropTypes from 'prop-types';
// import ReactGA from 'react-ga4';
// import {Route, Routes, useLocation} from 'react-router-dom';

// function isProbablyBot(userAgent) {
//   const botKeywords = ['bot', 'crawl', 'spider', 'curl', 'wget', 'slurp', 'mediapartners'];
//   const ogDataFetchers = ['facebookexternalhit', 'facebot', 'pinterestbot']; // Add more as needed

//   const isKnownBot = botKeywords.some(keyword => userAgent.toLowerCase().includes(keyword));
//   const isOGDataFetcher = ogDataFetchers.some(keyword => userAgent.toLowerCase().includes(keyword));

//   if (isKnownBot && !isOGDataFetcher) {
//     return true; // It's a bot but not an OG data fetcher
//   }
//   return false; // Not a bot or an allowed OG data fetcher
// }

// class GoogleAnalytics extends Component {
//   componentDidMount() {
//     if (!isProbablyBot(navigator.userAgent)) {
//       const {location} = this.props;
//       const path = location.pathname + location.search;
//       ReactGA.send({hitType: 'pageview', page: path});
//     }
//   }

//   // componentDidUpdate({location: prevLocation}) {
//   //   if (!isProbablyBot(navigator.userAgent)) {
//   //     const {location} = this.props;
//   //     const path = location.pathname + location.search;
//   //     if (prevLocation.pathname + prevLocation.search !== path) {
//   //       ReactGA.send({hitType: 'pageview', page: path});
//   //     }
//   //   }
//   // }

//   render() {
//     return null;
//   }
// }

// GoogleAnalytics.propTypes = {
//   location: PropTypes.shape({
//     pathname: PropTypes.string,
//     search: PropTypes.string,
//   }).isRequired,
// };

// const RouteTracker = function () {
//   const location = useLocation();

//   useEffect(() => {
//     if (!isProbablyBot(navigator.userAgent)) {
//       const path = location.pathname + location.search;
//       ReactGA.send({hitType: 'pageview', page: path});
//     }
//   }, [location.pathname, location.search]);

//   return null;
// };

// const Transaction = function (props) {
//   const {invoice: inv, sub} = props;

//   ReactGA.event({
//     name: 'purchase',
//     params: {
//       currency: inv.currency,
//       value: sub.revenue,
//       items: [
//         {
//           item_id: inv.id,
//           item_name: inv.name,
//           item_category: inv.category,
//           price: inv.price,
//           quantity: 1,
//         },
//       ],
//       transaction_id: sub.id,
//     },
//   });
// };

// const init = (options = {}) => {
//   const isGAEnabled = !!'G-DTHTQM9V6V';

//   if (isGAEnabled && !isProbablyBot(navigator.userAgent)) {
//     ReactGA.initialize('G-DTHTQM9V6V', {
//       // debug: true,
//       ...options,
//     });
//   }

//   return isGAEnabled;
// };

// export default {
//   GoogleAnalytics,
//   RouteTracker,
//   init,
//   Transaction,
// };
