import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, Slider, Button, Grid} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {getFeatureLimit} from 'app/utils/getFeatureLimit';

const basePricePerCredit = 0.21;

function CreditSlider({
  userPlan,
  onPurchase,
  showBuyButton = true,
  formatCurrency,
  currencyCode,
  currencySymbol,
  exchangeRate,
  isSmallScreen,
  UpdateState,
  // newly added:
  selectedPrice, // parent's selectedPrice if any
  handleTierUpgrade,
  toggle, // if you need monthly vs annually in here
}) {
  const theme = useTheme();

  // If user has no plan, default to 'free' for discount logic
  const actualUserPlan = userPlan || 'free';
  const isFreeUser = actualUserPlan.toLowerCase() === 'free';

  // We track the credits in local state. Start at 100 by default:
  const [credits, setCredits] = useState(100);

  // Keep track of whether we've initialised from parent's `selectedPrice`
  // so we don't repeatedly run that logic if user slides the slider later.
  const [initialisedFromParent, setInitialisedFromParent] = useState(false);

  // Fetch discount amounts from your getFeatureLimit logic
  const userDiscount = getFeatureLimit(actualUserPlan, 'Discount') || 0;
  const nextDiscount = getFeatureLimit(actualUserPlan, 'NextDiscount') || 0;
  const nextTier = getFeatureLimit(actualUserPlan, 'NextTier') || '';

  // console.log('userDiscount', userDiscount, 'nextDiscount', nextDiscount, 'nextTier', nextTier);

  // Reusable function to compute total price from credits
  const computePrice = (creditAmount, discountPct) => {
    const raw = creditAmount * basePricePerCredit; // in USD or base
    const discounted = raw * (1 - discountPct / 100);
    return discounted * (exchangeRate || 1); // convert to local
  };

  // Recompute the standard user price from local credits
  const discountedTotal = computePrice(credits, userDiscount);

  // Next discount price only used for display if you “🔥 Upgrade to nextTier”
  const nextDiscountedTotal = computePrice(credits, nextDiscount);

  // If user has a base price too, you might display that:
  const basePrice = credits * basePricePerCredit * (exchangeRate || 1);

  // Convert them to strings for display
  const basePriceStr = formatCurrency(basePrice, currencyCode, currencySymbol);
  const discountedTotalStr = formatCurrency(discountedTotal, currencyCode, currencySymbol);
  const nextDiscountedTotalStr = formatCurrency(nextDiscountedTotal, currencyCode, currencySymbol);

  // On mount or if parent's selectedPrice changes, if we haven't used it yet,
  // compute a matching credit amount for the slider.
  useEffect(() => {
    if (!initialisedFromParent && selectedPrice?.Price) {
      const priceInBaseCurrency = selectedPrice.Price; // parent's price is presumably in same currency after exchangeRate
      // Reverse-engineer the credit count: priceInBaseCurrency = credits * basePricePerCredit * (1 - userDiscount/100)
      const denominator = basePricePerCredit * (1 - userDiscount / 100);
      if (denominator > 0) {
        let computedCredits = priceInBaseCurrency / denominator;
        computedCredits = Math.round(computedCredits);
        if (computedCredits < 10) computedCredits = 10; // slider's minimum
        if (computedCredits > 5000) computedCredits = 5000; // slider's max
        setCredits(computedCredits);
      }
      setInitialisedFromParent(true);
    }
  }, [initialisedFromParent, selectedPrice, userDiscount]);

  // Also keep the parent's state in sync if needed:
  useEffect(() => {
    UpdateState({
      selectedPrice: {Price: discountedTotal},
    });
  }, [credits, userDiscount, discountedTotal, UpdateState]);

  // The user clicked “Buy Credits”:
  const handleBuyNowClick = () => {
    if (onPurchase) {
      onPurchase(credits, discountedTotal);
    }
  };

  const baseUrl = typeof window !== 'undefined' && window.location.pathname.startsWith('/pricing') ? '/pricing' : '/upgrade';

  return (
    <Box sx={{padding: '24px', borderRadius: '12px'}}>
      {/* Title row */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography
            variant="h6"
            sx={{fontWeight: 700, paddingBottom: '12px', fontSize: '24px !important'}}>
            {baseUrl === '/pricing' ? 'Buy Credits' : 'Buy Extra Credits'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              color: theme.palette.primary.main,
              textAlign: 'right',
              fontSize: '24px !important',
            }}>
            {credits} Credits
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="body1"
        sx={{
          marginBottom: '36px',
          fontWeight: 500,
          paddingTop: '8px',
          fontSize: '0.9rem !important',
        }}>
        Select how many credits you need:
      </Typography>

      {/* Slider */}
      <Box sx={{position: 'relative', marginBottom: '24px'}}>
        <Slider
          value={credits}
          onChange={(e, newValue) => {
            setCredits(newValue);
          }}
          min={10}
          max={5000}
          step={10}
          valueLabelDisplay="auto"
          sx={{
            height: 14,
            '& .MuiSlider-track': {
              height: 14,
              borderRadius: '34px',
              background: `linear-gradient(90deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
            },
            '& .MuiSlider-thumb': {
              height: 28,
              width: 28,
              backgroundColor: theme.palette.primary.main,
              transition: '0.2s ease',
              '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 16px ${theme.palette.primary.light}`,
              },
            },
          }}
        />
      </Box>

      {/* Pricing row */}
      <Grid container spacing={2} alignItems="center" sx={{marginBottom: '16px'}}>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
          }}>
          {/* If not free, you can show the “Base Price” crossed out */}
          {actualUserPlan !== 'free' && actualUserPlan !== 'Free' && (
            <Typography
              variant="body1"
              sx={{
                textDecoration: userDiscount > 0 ? 'line-through' : 'none',
                color: theme.palette.grey[700],
                fontWeight: 600,
              }}>
              Base Price: {basePriceStr || ''}
            </Typography>
          )}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              color: theme.palette.primary.dark,
              fontSize: isSmallScreen ? '1.4rem !important' : '2rem !important',
              paddingTop: '12px',
            }}>
            Your Price: {discountedTotalStr || ''}
          </Typography>
          {userDiscount > 0 ? (
            <Typography
              variant="body2"
              sx={{color: theme.palette.success.dark, fontWeight: 600, paddingTop: '14px'}}>
              🎉 You save {userDiscount || ''}%!
            </Typography>
          ) : (
            <Typography
              variant="body2"
              sx={{color: theme.palette.warning.dark, fontWeight: 600, paddingTop: '18px'}}>
              ⚠️ Unlock a discount by {baseUrl === '/pricing' ? 'subscribing' : 'upgrading'}!
            </Typography>
          )}
        </Grid>

        {/* If there's a nextTier, show clickable “🔥 Upgrade to ...” box */}
        {nextTier && (
          <Grid
            item
            xs={12}
            sm={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minHeight: '180px',
            }}>
            <Box
              sx={{
                backgroundColor: `${theme.palette.primary.light}15`,
                padding: '10px 14px',
                borderRadius: '10px',
                textAlign: 'center',
                marginTop: {xs: '12px', sm: '0'},
                border: `1px solid ${theme.palette.primary.main}30`,
                cursor: 'pointer', // indicate click
              }}
              onClick={() => handleTierUpgrade && handleTierUpgrade(nextTier)}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  color: theme.palette.primary.dark,
                  paddingBottom: '18px',
                  paddingTop: '24px',
                }}>
                🔥 Upgrade to <strong>{nextTier}</strong> for {nextDiscount || ''}% off!
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                  fontSize: '1.1rem',
                  paddingBottom: '24px',
                }}>
                Pay only {nextDiscountedTotalStr || ''}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Buy button */}
      {showBuyButton && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '44px',
            paddingBottom: '18px',
            position: 'relative',
            zIndex: 2,
          }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              maxWidth: '280px',
              borderRadius: '36px',
              padding: '14px 32px',
              fontSize: '1.1rem',
              fontWeight: 600,
              textTransform: 'none',
              transition: '0.3s ease',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                boxShadow: `0px 6px 16px ${theme.palette.primary.light}`,
              },
            }}
            onClick={handleBuyNowClick}>
            Buy Credits
          </Button>
        </Box>
      )}
    </Box>
  );
}

CreditSlider.propTypes = {
  userPlan: PropTypes.string.isRequired,
  onPurchase: PropTypes.func,
  showBuyButton: PropTypes.bool,
  formatCurrency: PropTypes.func.isRequired,
  currencyCode: PropTypes.string,
  currencySymbol: PropTypes.string,
  exchangeRate: PropTypes.number,
  isSmallScreen: PropTypes.bool,
  UpdateState: PropTypes.func,
  selectedPrice: PropTypes.shape({
    Price: PropTypes.number,
  }),
  handleTierUpgrade: PropTypes.func, // newly added
  toggle: PropTypes.string,
};

export default CreditSlider;

// import React, {useState, useEffect} from 'react';
// import PropTypes from 'prop-types';
// import {Box, Typography, Slider, Button, Grid} from '@mui/material';
// import {useTheme} from '@mui/material/styles';
// import {getFeatureLimit} from 'app/utils/getFeatureLimit';

// const basePricePerCredit = 0.21;

// function CreditSlider({
//   userPlan,
//   onPurchase,
//   showBuyButton = true,
//   formatCurrency,
//   currencyCode,
//   currencySymbol,
//   exchangeRate,
//   isSmallScreen,
//   UpdateState,
// }) {
//   const [credits, setCredits] = useState(100);
//   const theme = useTheme();

//   if (!userPlan) {
//     userPlan = 'free';
//   }

//   const userDiscount = getFeatureLimit(userPlan, 'Discount') || 0;
//   const nextDiscount = getFeatureLimit(userPlan, 'NextDiscount') || 0;
//   const nextTier = getFeatureLimit(userPlan, 'NextTier') || 0;

//   const basePrice = credits * basePricePerCredit;
//   const discountedTotal = basePrice * (1 - userDiscount / 100);
//   const nextDiscountedTotal = basePrice * (1 - nextDiscount / 100);

//   const basePriceLocal = basePrice * (exchangeRate || 1);
//   const discountedPriceLocal = discountedTotal * (exchangeRate || 1);
//   const nextDiscountedTotalLocal = nextDiscountedTotal * (exchangeRate || 1);

//   const basePriceStr = formatCurrency(basePriceLocal, currencyCode, currencySymbol);
//   const discountedTotalStr = formatCurrency(discountedPriceLocal, currencyCode, currencySymbol);
//   const nextDiscountedTotalStr = formatCurrency(
//     nextDiscountedTotalLocal,
//     currencyCode,
//     currencySymbol
//   );

//   useEffect(() => {
//     console.log('Credits:', credits, basePricePerCredit, userDiscount);
//     UpdateState({
//       selectedPrice: {
//         Price: credits * basePricePerCredit * (1 - userDiscount / 100),
//       },
//     });
//   }, [credits, userDiscount, UpdateState]);

//   const handleBuyNowClick = () => {
//     if (onPurchase) {
//       onPurchase(credits, discountedPriceLocal);
//     }
//   };

//   return (
//     <Box sx={{padding: '24px', borderRadius: '12px'}}>
//       {/* Title & Credit Count */}
//       <Grid container justifyContent="space-between" alignItems="center">
//         <Grid item>
//           <Typography
//             variant="h6"
//             sx={{fontWeight: 700, paddingBottom: '12px', fontSize: '24px !important'}}>
//             Buy Extra Credits
//           </Typography>
//         </Grid>
//         <Grid item>
//           <Typography
//             variant="h6"
//             sx={{
//               fontWeight: 700,
//               color: theme.palette.primary.main,
//               textAlign: 'right',
//               fontSize: '24px !important',
//             }}>
//             {credits} Credits
//           </Typography>
//         </Grid>
//       </Grid>

//       <Typography
//         variant="body1"
//         sx={{
//           marginBottom: '36px',
//           fontWeight: 500,
//           paddingTop: '8px',
//           fontSize: '0.9rem !important',
//         }}>
//         Select how many extra credits you need:
//       </Typography>

//       {/* Improved Slider with Linked Value */}
//       <Box sx={{position: 'relative', marginBottom: '24px'}}>
//         <Slider
//           value={credits}
//           onChange={(e, newValue) => {
//             setCredits(newValue);
//             UpdateState({
//               selectedPrice: {
//                 Price: newValue * basePricePerCredit * (1 - userDiscount / 100),
//               },
//             });
//           }}
//           min={10}
//           max={5000}
//           step={10}
//           valueLabelDisplay="auto"
//           sx={{
//             height: 14, // Slightly thicker track
//             '& .MuiSlider-track': {
//               height: 14,
//               borderRadius: '34px', // Updated border radius
//               background: `linear-gradient(90deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
//             },
//             '& .MuiSlider-thumb': {
//               height: 28,
//               width: 28,
//               backgroundColor: theme.palette.primary.main,
//               transition: '0.2s ease',
//               '&:hover, &.Mui-focusVisible': {
//                 boxShadow: `0px 0px 16px ${theme.palette.primary.light}`,
//               },
//             },
//           }}
//         />
//         {/* <Typography
//           variant="body2"
//           sx={{
//             position: 'absolute',
//             top: '-28px',
//             left: '50%',
//             transform: 'translateX(-50%)',
//             fontWeight: 600,
//             color: theme.palette.primary.dark,
//           }}
//         >
//           {credits} Credits
//         </Typography> */}
//       </Box>

//       {/* Two-Column Price Layout */}
//       <Grid container spacing={2} alignItems="center" sx={{marginBottom: '16px'}}>
//         <Grid
//           item
//           xs={12}
//           sm={7}
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             textAlign: 'center',
//           }}>
//           {userPlan !== 'free' ? (
//             <Typography
//               variant="body1"
//               sx={{
//                 textDecoration: userDiscount > 0 ? 'line-through' : 'none',
//                 color: theme.palette.grey[700],
//                 fontWeight: 600,
//               }}>
//               Base Price: {basePriceStr || ''}
//             </Typography>
//           ) : null}
//           <Typography
//             variant="h6"
//             sx={{
//               fontWeight: 700,
//               color: theme.palette.primary.dark,
//               fontSize: isSmallScreen ? '1.4rem !important' : '2rem !important', // Slightly bigger
//               paddingTop: '12px',
//             }}>
//             Your Price: {discountedTotalStr || ''}
//           </Typography>
//           {userDiscount > 0 ? (
//             <Typography
//               variant="body2"
//               sx={{color: theme.palette.success.dark, fontWeight: 600, paddingTop: '14px'}}>
//               🎉 You save {userDiscount || ''}%!
//             </Typography>
//           ) : (
//             <Typography
//               variant="body2"
//               sx={{color: theme.palette.warning.dark, fontWeight: 600, paddingTop: '18px'}}>
//               ⚠️ Unlock a discount by upgrading!
//             </Typography>
//           )}
//         </Grid>

//         {/* Upsell Section - Smaller & Less Dominant */}
//         {nextTier && (
//           <Grid
//             item
//             xs={12}
//             sm={5}
//             sx={{
//               // height: '96px',
//               display: 'flex',
//               flexDirection: 'column',
//               justifyContent: 'center',
//               minHeight: '180px',
//               // marginTop: '18px',
//             }}>
//             <Box
//               sx={{
//                 backgroundColor: `${theme.palette.primary.light}15`, // Lighter background for visibility
//                 padding: '10px 14px',
//                 borderRadius: '10px',
//                 textAlign: 'center',
//                 marginTop: {xs: '12px', sm: '0'}, // Adjust for mobile stacking
//                 border: `1px solid ${theme.palette.primary.main}30`, // Subtle border
//               }}
//               onClick={(e) => {
//                 console.log('clicked');
//               }}>
//               <Typography
//                 variant="body2"
//                 sx={{
//                   fontWeight: 500,
//                   color: theme.palette.primary.dark,
//                   paddingBottom: '18px',
//                   paddingTop: '24px',
//                 }}>
//                 🔥 Upgrade to <strong>{nextTier}</strong> for {nextDiscount || ''}% off!
//               </Typography>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontWeight: 700,
//                   color: theme.palette.primary.main,
//                   fontSize: '1.1rem',
//                   paddingBottom: '24px',
//                 }}>
//                 Pay only {nextDiscountedTotalStr || ''}
//               </Typography>
//             </Box>
//           </Grid>
//         )}
//       </Grid>

//       {/* Centered Buy Button */}
//       {showBuyButton && (
//         <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             marginTop: '44px', // Reduce top margin
//             paddingBottom: '18px', // Add padding to prevent overlap
//             position: 'relative', // Ensure it respects the container
//             zIndex: 2, // Ensure it doesn't get hidden
//           }}>
//           <Button
//             variant="contained"
//             color="primary"
//             fullWidth // Ensure it scales properly on smaller screens
//             sx={{
//               maxWidth: '280px', // Prevents the button from being too wide
//               borderRadius: '36px',
//               padding: '14px 32px',
//               fontSize: '1.1rem',
//               fontWeight: 600,
//               textTransform: 'none',
//               transition: '0.3s ease',
//               '&:hover': {
//                 backgroundColor: theme.palette.primary.dark,
//                 boxShadow: `0px 6px 16px ${theme.palette.primary.light}`,
//               },
//             }}
//             onClick={handleBuyNowClick}>
//             Buy Credits
//           </Button>
//         </Box>
//       )}
//     </Box>
//   );
// }

// CreditSlider.propTypes = {
//   userPlan: PropTypes.string.isRequired,
//   onPurchase: PropTypes.func,
//   showBuyButton: PropTypes.bool,
//   formatCurrency: PropTypes.func.isRequired,
//   currencyCode: PropTypes.string,
//   currencySymbol: PropTypes.string,
//   exchangeRate: PropTypes.number,
//   isSmallScreen: PropTypes.bool,
//   UpdateState: PropTypes.func,
// };

// export default CreditSlider;

// // import React, {useState} from 'react';
// // import PropTypes from 'prop-types';
// // import {Box, Typography, Slider, Button, Grid, Paper, AppBar, Toolbar} from '@mui/material';
// // import {useTheme} from '@mui/material/styles';
// // import {getFeatureLimit} from 'app/utils/getFeatureLimit';

// // const basePricePerCredit = 0.21;

// // function CreditSlider({
// //   userPlan,
// //   onPurchase,
// //   showBuyButton = true,
// //   formatCurrency,
// //   currencyCode,
// //   currencySymbol,
// //   exchangeRate,
// // }) {
// //   const [credits, setCredits] = useState(100);
// //   const theme = useTheme();

// //   const userDiscount = getFeatureLimit(userPlan, 'Discount') || 0;
// //   const nextDiscount = getFeatureLimit(userPlan, 'NextDiscount') || 0;
// //   const nextTier = getFeatureLimit(userPlan, 'NextTier') || 0;

// //   const basePrice = credits * basePricePerCredit;
// //   const discountedTotal = basePrice * (1 - userDiscount / 100);
// //   const nextDiscountedTotal = basePrice * (1 - nextDiscount / 100);

// //   const basePriceLocal = basePrice * (exchangeRate || 1);
// //   const discountedPriceLocal = discountedTotal * (exchangeRate || 1);
// //   const nextDiscountedTotalLocal = nextDiscountedTotal * (exchangeRate || 1);

// //   const basePriceStr = formatCurrency(basePriceLocal, currencyCode, currencySymbol);
// //   const discountedTotalStr = formatCurrency(discountedPriceLocal, currencyCode, currencySymbol);
// //   const nextDiscountedTotalStr = formatCurrency(
// //     nextDiscountedTotalLocal,
// //     currencyCode,
// //     currencySymbol
// //   );

// //   const handleBuyNowClick = () => {
// //     if (onPurchase) {
// //       onPurchase(credits, discountedPriceLocal);
// //     }
// //   };

// //   return (
// //     <Box sx={{padding: '24px', borderRadius: '12px'}}>
// //       {/* Toolbar with Title + Credit Count */}
// //       <Grid container justifyContent="space-between" alignItems="center" sx={{padding: 0}}>
// //         <Grid item>
// //           <Typography variant="h6" sx={{fontWeight: 700}}>
// //             Buy Extra Credits
// //           </Typography>
// //         </Grid>
// //         <Grid item>
// //           <Typography variant="h6" sx={{fontWeight: 700, color: theme.palette.primary.main}}>
// //             {credits} Credits
// //           </Typography>
// //         </Grid>
// //       </Grid>

// //       <Typography variant="body1" sx={{marginBottom: '16px', fontWeight: 500}}>
// //         Select how many extra credits you need:
// //       </Typography>

// //       {/* Improved Slider */}
// //       <Slider
// //         value={credits}
// //         onChange={(e, newValue) => setCredits(newValue)}
// //         min={10}
// //         max={5000}
// //         step={10}
// //         valueLabelDisplay="auto"
// //         sx={{
// //           height: 10, // Thicker track
// //           '& .MuiSlider-track': {
// //             height: 10,
// //             borderRadius: '6px',
// //             background: `linear-gradient(90deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
// //           },
// //           '& .MuiSlider-thumb': {
// //             height: 24,
// //             width: 24,
// //             backgroundColor: theme.palette.primary.main,
// //             transition: '0.2s ease',
// //             '&:hover, &.Mui-focusVisible': {
// //               boxShadow: `0px 0px 12px ${theme.palette.primary.light}`,
// //             },
// //           },
// //         }}
// //       />

// //       {/* Two-Column Price Layout */}
// //       <Grid container spacing={2} alignItems="center" sx={{marginBottom: '16px'}}>
// //         <Grid item xs={12} sm={7}>
// //           <Typography
// //             variant="body1"
// //             sx={{
// //               textDecoration: userDiscount > 0 ? 'line-through' : 'none',
// //               color: theme.palette.grey[700],
// //               fontWeight: 600,
// //             }}>
// //             Base Price: {basePriceStr}
// //           </Typography>
// //           <Typography
// //             variant="h6"
// //             sx={{
// //               fontWeight: 700,
// //               color: theme.palette.primary.dark,
// //               fontSize: '1.2rem',
// //             }}>
// //             Your Price: {discountedTotalStr}
// //           </Typography>
// //           {userDiscount > 0 ? (
// //             <Typography variant="body2" sx={{color: theme.palette.success.dark, fontWeight: 600}}>
// //               🎉 You save {userDiscount}%!
// //             </Typography>
// //           ) : (
// //             <Typography variant="body2" sx={{color: theme.palette.warning.dark, fontWeight: 600}}>
// //               ⚠️ No discount on Free plan
// //             </Typography>
// //           )}
// //         </Grid>

// //         <Grid item xs={12} sm={5}>
// //           {nextTier && (
// //             <Paper
// //               sx={{
// //                 backgroundColor: theme.palette.background.default,
// //                 padding: '12px 16px',
// //                 borderRadius: '12px',
// //                 marginBottom: '20px',
// //                 boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
// //               }}>
// //               <Typography variant="body2">
// //                 🔥 Upgrade to <strong>{nextTier}</strong> and get a {nextDiscount}% discount!
// //               </Typography>
// //               <Typography
// //                 variant="h6"
// //                 sx={{
// //                   fontWeight: 700,
// //                   color: theme.palette.primary.main,
// //                   fontSize: '1.1rem',
// //                 }}>
// //                 Pay only {nextDiscountedTotalStr}
// //               </Typography>
// //             </Paper>
// //           )}
// //         </Grid>
// //       </Grid>

// //       {/* Upgrade Offer */}

// //       {/* Centered Buy Button */}
// //       {showBuyButton && (
// //         <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
// //           <Button
// //             variant="contained"
// //             color="primary"
// //             sx={{
// //               borderRadius: '24px',
// //               padding: '12px 28px',
// //               fontSize: '1rem',
// //               fontWeight: 600,
// //               textTransform: 'none',
// //               transition: '0.3s ease',
// //               '&:hover': {
// //                 backgroundColor: theme.palette.primary.dark,
// //                 boxShadow: `0px 4px 12px ${theme.palette.primary.light}`,
// //               },
// //             }}
// //             onClick={handleBuyNowClick}>
// //             Buy Now
// //           </Button>
// //         </Box>
// //       )}
// //     </Box>
// //   );
// // }

// // CreditSlider.propTypes = {
// //   userPlan: PropTypes.string.isRequired,
// //   onPurchase: PropTypes.func,
// //   showBuyButton: PropTypes.bool,
// //   formatCurrency: PropTypes.func.isRequired,
// //   currencyCode: PropTypes.string,
// //   currencySymbol: PropTypes.string,
// //   exchangeRate: PropTypes.number,
// // };

// // export default CreditSlider;

// // // import React, { useState } from 'react';
// // // import PropTypes from 'prop-types';
// // // import { Box, Typography, Slider, Button, Paper } from '@mui/material';
// // // import { useTheme } from '@mui/material/styles';
// // // import { getFeatureLimit } from 'app/utils/getFeatureLimit';

// // // const basePricePerCredit = 0.21;

// // // function CreditSlider({
// // //   userPlan,
// // //   onPurchase,
// // //   showBuyButton = true,
// // //   formatCurrency,
// // //   currencyCode,
// // //   currencySymbol,
// // //   exchangeRate,
// // // }) {
// // //   const [credits, setCredits] = useState(100);
// // //   const theme = useTheme();

// // //   const userDiscount = getFeatureLimit(userPlan, 'Discount') || 0;
// // //   const nextDiscount = getFeatureLimit(userPlan, 'NextDiscount') || 0;
// // //   const nextTier = getFeatureLimit(userPlan, 'NextTier') || 0;

// // //   const basePrice = credits * basePricePerCredit;
// // //   const discountedTotal = basePrice * (1 - userDiscount / 100);
// // //   const nextDiscountedTotal = basePrice * (1 - nextDiscount / 100);

// // //   const basePriceLocal = basePrice * (exchangeRate || 1);
// // //   const discountedPriceLocal = discountedTotal * (exchangeRate || 1);
// // //   const nextDiscountedTotalLocal = nextDiscountedTotal * (exchangeRate || 1);

// // //   const basePriceStr = formatCurrency(basePriceLocal, currencyCode, currencySymbol);
// // //   const discountedTotalStr = formatCurrency(discountedPriceLocal, currencyCode, currencySymbol);
// // //   const nextDiscountedTotalStr = formatCurrency(nextDiscountedTotalLocal, currencyCode, currencySymbol);

// // //   const handleBuyNowClick = () => {
// // //     if (onPurchase) {
// // //       onPurchase(credits, discountedPriceLocal);
// // //     }
// // //   };

// // //   return (
// // //     <Box sx={{ textAlign: 'left', padding: '24px' }}>
// // //       <Typography variant="h5" sx={{ fontWeight: 700, marginBottom: '16px' }}>
// // //         Buy Extra Credits
// // //       </Typography>
// // //       <Typography variant="body1" sx={{ marginBottom: '16px', fontWeight: 500 }}>
// // //         Select how many extra credits you need:
// // //       </Typography>

// // //       {/* Styled Slider */}
// // //       <Slider
// // //         value={credits}
// // //         onChange={(e, newValue) => setCredits(newValue)}
// // //         min={10}
// // //         max={5000}
// // //         step={10}
// // //         valueLabelDisplay="auto"
// // //         sx={{
// // //           color: theme.palette.primary.main,
// // //           height: 8,
// // //           '& .MuiSlider-track': {
// // //             background: `linear-gradient(90deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
// // //             border: 'none',
// // //           },
// // //           '& .MuiSlider-thumb': {
// // //             height: 20,
// // //             width: 20,
// // //             backgroundColor: theme.palette.primary.main,
// // //             transition: '0.2s ease',
// // //             '&:hover, &.Mui-focusVisible': {
// // //               boxShadow: `0px 0px 10px ${theme.palette.primary.light}`,
// // //             },
// // //           },
// // //         }}
// // //       />

// // //       {/* Price Breakdown */}
// // //       <Box sx={{ marginBottom: '16px' }}>
// // //         <Typography variant="body1" sx={{ marginBottom: '4px', color: theme.palette.grey[700] }}>
// // //           <strong>Base Price:</strong> <s>{basePriceStr}</s>
// // //         </Typography>
// // //         <Typography
// // //           variant="h6"
// // //           sx={{
// // //             marginBottom: '4px',
// // //             fontWeight: 700,
// // //             color: theme.palette.primary.dark,
// // //             fontSize: '1.2rem',
// // //           }}
// // //         >
// // //           <strong>Your Price:</strong> {discountedTotalStr}
// // //         </Typography>
// // //         <Typography variant="body2" sx={{ color: theme.palette.success.dark, fontWeight: 600 }}>
// // //           🎉 You save {userDiscount}%!
// // //         </Typography>
// // //       </Box>

// // //       {/* Upgrade Prompt */}
// // //       {nextTier && (
// // //         <Paper
// // //           sx={{
// // //             backgroundColor: theme.palette.background.default,
// // //             padding: '12px 16px',
// // //             borderRadius: '12px',
// // //             marginBottom: '20px',
// // //             boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
// // //           }}
// // //         >
// // //           <Typography variant="body2">
// // //             🔥 Upgrade to <strong>{nextTier}</strong> and get a {nextDiscount}% discount!
// // //           </Typography>
// // //           <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.primary.main }}>
// // //             Pay only {nextDiscountedTotalStr}
// // //           </Typography>
// // //         </Paper>
// // //       )}

// // //       {/* Buy Button */}
// // //       {showBuyButton && (
// // //         <Button
// // //           variant="contained"
// // //           color="primary"
// // //           sx={{
// // //             borderRadius: '24px',
// // //             padding: '12px 28px',
// // //             fontSize: '1rem',
// // //             fontWeight: 600,
// // //             textTransform: 'none',
// // //             transition: '0.3s ease',
// // //             '&:hover': {
// // //               backgroundColor: theme.palette.primary.dark,
// // //               boxShadow: `0px 4px 12px ${theme.palette.primary.light}`,
// // //             },
// // //           }}
// // //           onClick={handleBuyNowClick}
// // //         >
// // //           Buy Now
// // //         </Button>
// // //       )}
// // //     </Box>
// // //   );
// // // }

// // // CreditSlider.propTypes = {
// // //   userPlan: PropTypes.string.isRequired,
// // //   onPurchase: PropTypes.func,
// // //   showBuyButton: PropTypes.bool,
// // //   formatCurrency: PropTypes.func.isRequired,
// // //   currencyCode: PropTypes.string,
// // //   currencySymbol: PropTypes.string,
// // //   exchangeRate: PropTypes.number,
// // // };

// // // export default CreditSlider;

// // // // import React, {useState} from 'react';
// // // // import PropTypes from 'prop-types';
// // // // import {Box, Typography, Slider, Button} from '@mui/material';
// // // // import {useTheme} from '@mui/material/styles';
// // // // import {getFeatureLimit} from 'app/utils/getFeatureLimit';

// // // // const basePricePerCredit = 0.21;

// // // // const getNextTier = (currentTier) => {
// // // //   switch (currentTier) {
// // // //     case 'Free':
// // // //       return 'Starter';
// // // //     case 'Starter':
// // // //       return 'Pro';
// // // //     case 'Pro':
// // // //       return 'Enterprise';
// // // //     default:
// // // //       return null;
// // // //   }
// // // // };

// // // // function CreditSlider({
// // // //   userPlan,
// // // //   onPurchase, // [CHANGED] We'll call this when user hits Buy
// // // //   showBuyButton = true,
// // // //   formatCurrency,
// // // //   currencyCode,
// // // //   currencySymbol,
// // // //   exchangeRate,
// // // // }) {
// // // //   const [credits, setCredits] = useState(100);
// // // //   const theme = useTheme();

// // // //   const userDiscount = getFeatureLimit(userPlan, 'Discount') || '';

// // // //   const nextDiscount = getFeatureLimit(userPlan, 'NextDiscount') || '';

// // // //   const nextTier = getFeatureLimit(userPlan, 'NextTier') || '';

// // // //   const basePrice = credits * basePricePerCredit;
// // // //   const discountedTotal = basePrice * (1 - userDiscount / 100);

// // // //   const basePriceLocal = basePrice * (exchangeRate || 1);
// // // //   const discountedPriceLocal = discountedTotal * (exchangeRate || 1);

// // // //   const basePriceStr = formatCurrency(basePriceLocal, currencyCode, currencySymbol);
// // // //   const discountedTotalStr = formatCurrency(discountedPriceLocal, currencyCode, currencySymbol);

// // // //   const nextDiscountedTotal = basePrice * (1 - nextDiscount / 100);
// // // //   const nextDiscountedTotalLocal = nextDiscountedTotal * (exchangeRate || 1);
// // // //   const nextDiscountedTotalStr = formatCurrency(
// // // //     nextDiscountedTotalLocal,
// // // //     currencyCode,
// // // //     currencySymbol
// // // //   );

// // // //   // [ADDED] We want a numeric price for URL param, so we keep discountedPriceLocal as a plain number
// // // //   // for the final checkout link. That way we don't pass currency symbols in the URL.
// // // //   const handleBuyNowClick = () => {
// // // //     if (onPurchase) {
// // // //       onPurchase(credits, discountedPriceLocal);
// // // //     }
// // // //   };

// // // //   return (
// // // //     <Box sx={{textAlign: 'left', padding: '20px'}}>
// // // //       <Typography variant="h5" sx={{fontWeight: '600 !important', marginBottom: '12px'}}>
// // // //         Buy Extra Credits
// // // //       </Typography>
// // // //       <Typography variant="body" sx={{marginBottom: '16px', fontWeight: '500 !important'}}>
// // // //         Select how many extra credits you need:
// // // //       </Typography>

// // // //       <Slider
// // // //         value={credits}
// // // //         onChange={(e, newValue) => setCredits(newValue)}
// // // //         min={10}
// // // //         max={5000}
// // // //         step={10}
// // // //         valueLabelDisplay="auto"
// // // //         sx={{color: theme.palette.primary.main, marginBottom: '24px', marginTop: '24px'}}
// // // //       />

// // // //       <Typography variant="body1" sx={{marginBottom: '8px'}}>
// // // //         <strong>Base Price (no discount):</strong> {basePriceStr}
// // // //       </Typography>

// // // //       {userPlan !== 'Free' && (
// // // //         <>
// // // //           <Typography variant="body1" sx={{marginBottom: '8px'}}>
// // // //             <strong>Discount on current tier:</strong> {userDiscount}%
// // // //           </Typography>
// // // //           <Typography variant="body1" sx={{marginBottom: '16px'}}>
// // // //             <strong>Your Price:</strong> {discountedTotalStr || ''} (after discount)
// // // //           </Typography>
// // // //         </>
// // // //       )}

// // // //       {userPlan === 'Free' && (
// // // //         <Typography variant="body1" sx={{marginBottom: '16px'}}>
// // // //           <strong>Your Price:</strong> {discountedTotalStr || ''}
// // // //         </Typography>
// // // //       )}

// // // //       {nextTier && (
// // // //         <Typography variant="body2" sx={{marginBottom: '20px'}}>
// // // //           If you upgraded to <strong>{nextTier}</strong>, you’d get a {nextDiscount || ''}% discount and
// // // //           pay <strong>{nextDiscountedTotalStr || ''}</strong>.
// // // //         </Typography>
// // // //       )}

// // // //       {showBuyButton && (
// // // //         <Button
// // // //           variant="contained"
// // // //           color="primary"
// // // //           sx={{borderRadius: '24px', padding: '10px 24px'}}
// // // //           onClick={handleBuyNowClick}>
// // // //           Buy Now
// // // //         </Button>
// // // //       )}
// // // //     </Box>
// // // //   );
// // // // }

// // // // CreditSlider.propTypes = {
// // // //   userPlan: PropTypes.string.isRequired,
// // // //   onPurchase: PropTypes.func,
// // // //   showBuyButton: PropTypes.bool,
// // // //   formatCurrency: PropTypes.func.isRequired,
// // // //   currencyCode: PropTypes.string,
// // // //   currencySymbol: PropTypes.string,
// // // //   exchangeRate: PropTypes.number,
// // // // };

// // // // export default CreditSlider;
