import {PROFILE, AUTH_LOGOUT} from 'app/constants/actionTypes';
import {HTTP_REQUEST_PROFILE} from 'app/services/httpClient/profile.service';
import {setActionType} from 'support/helpers/front';
import {v4 as uuidv4} from 'uuid';
import {toast} from 'react-toastify';
import Cookies from 'js-cookie';

/**
 * Profile action.
 *
 * @export
 * @async
 * @function
 * @name PROFILE_ACTION
 * @param token
 * @returns {Function}
 */
export function PROFILE_ACTION(token) {
  if (token === null) {
    const date = Date();
    try {
      let chatid = '';
      const newid = uuidv4();
      if (!Cookies.get('fmschatid')) {
        Cookies.set('fmschatid', newid, {expires: 365});
        chatid = newid;
      } else {
        chatid = Cookies.get('fmschatid');
      }
      return async (dispatch, getState) => {
        const PROFILE_RESPONSE = {
          response: {
            data: {
              active_profiles: [],
              billingAddress: undefined,
              businessName: undefined,
              cancel_at_period_end: undefined,
              city: undefined,
              country: undefined,
              createdAt: date,
              current_period_end: undefined,
              daily_leads: undefined,
              email: undefined,
              exp_month: undefined,
              exp_year: undefined,
              firstName: undefined,
              jobTitle: undefined,
              last4: undefined,
              lastName: undefined,
              level: undefined,
              max_profiles: undefined,
              nameOnCard: undefined,
              paymentMethod_brand: undefined,
              phone: undefined,
              postCodeZip: undefined,
              remaining_leads: undefined,
              status: undefined,
              stripeCustomerId: undefined,
              sub_id: undefined,
              subscription: undefined,
              nextnotification: undefined,
              allownotifications: undefined,
              notifications: undefined,
              _id: chatid,
              leads: [],
              enriched: [],
              archive: [],
              gleads: [],
              optedout: [],
            },
          },
        };
        // Dispatch Action.
        dispatch({
          type: setActionType(200, PROFILE),
          payload: PROFILE_RESPONSE,
        });
      };
    } catch (error) {
      console.error('Error handling profile actions:', error);
    }
  } else {
    return async (dispatch, getState) => {
      try {
        // console.log('PROFILE ACTION REQUEST', token)
        const PROFILE_RESPONSE = await HTTP_REQUEST_PROFILE(token);

        if (PROFILE_RESPONSE.status === 401) {
          console.error('Unauthorized: Invalid token', token);
          dispatch({type: AUTH_LOGOUT}); // This will trigger the logout action
          // toast.error("Your session has expired. Click 'Remember me' on login to stay signed in.");
          return;
        }

        if (!PROFILE_RESPONSE) {
          console.error('Received null or undefined profile data');
          return;
        }

        // console.log('PROFILE ACTION 1', PROFILE_RESPONSE);

        if (PROFILE_RESPONSE === null) {
          console.error('Received null PROFILE_RESPONSE');
          return;
        }

        if (PROFILE_RESPONSE.status === undefined) {
          console.error('Undefined status in PROFILE_RESPONSE');
          return;
        }

        if (!PROFILE_RESPONSE.ok) {
          console.error(`HTTP error: ${PROFILE_RESPONSE.status}`);
          return;
        }

        // const payloadData = await PROFILE_RESPONSE.json();

        const data = await PROFILE_RESPONSE.json();
        // Dispatch Action.
        dispatch({
          type: setActionType(200, PROFILE),
          payload: data,
        });
      } catch (error) {
        console.error('Error occurred while making HTTP request or parsing JSON:', error);
      }
    };
  }
}
