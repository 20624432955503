/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Fab from '@mui/material/Fab';
import ChatIcon from '@mui/icons-material/Chat';
import {PROFILE_ACTION} from 'app/actions/profileAction';
import {LOGOUT_ACTION} from 'app/actions/authAction';
import withRouter from 'app/utils/withRouter';
import Menu from '@mui/material/Menu';
// import {withStyles} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {toast} from 'react-toastify';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import Badge from '@mui/material/Badge';
import ClearIcon from '@mui/icons-material/Clear';
import Container from '@mui/material/Container';
import {v4 as uuidv4} from 'uuid';
import io from 'socket.io-client';
import Paper from '@mui/material/Paper';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {HTTP_SUBMIT_MESSAGE} from 'app/services/httpClient/gather.service';
import {HTTP_REQUEST_CHAT, HTTP_ADD_CHAT} from 'app/services/httpClient/chat.service';
import ChatApp from 'app/components/livechat/ChatApp';
import config from 'app/components/livechat/config';

function mapStateToProps(state) {
  return {
    GET_PROFILE_STATE: state.PROFILE_REDUCER.Profile,
    GET_AUTH_STATE: state.AUTH_REDUCER.Auth,
    GET_ERRORS_STATE: state.ERROR_REDUCER.Errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    DISPATCH_PROFILE: (token) => dispatch(PROFILE_ACTION(token)),
    DISPATCH_LOGOUT_ACTION: () => dispatch(LOGOUT_ACTION()),
  };
}

class Messenger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'active',
      anchorEl: null,
      open: false,
      live: false,
      email: '',
      name: '',
      message: '',
      username: '',
      messages: [],
      newmessage: false,
      msgcount: 0,
    };
    this.AddStatus = this.AddStatus.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.HandleSubmit = this.HandleSubmit.bind(this);
    this.sendHandler = this.sendHandler.bind(this);
    this.OpenMessages = this.OpenMessages.bind(this);
    this.emitUserDetails = this.emitUserDetails.bind(this);
  }

  async componentDidMount() {
    const {DISPATCH_PROFILE, GET_AUTH_STATE} = this.props;
    await DISPATCH_PROFILE(GET_AUTH_STATE.token);
    const {GET_PROFILE_STATE} = this.props;
    let chatid = '';
    if (GET_PROFILE_STATE._id) {
      // if (Cookies.get('newmsgs')) {
      //   chatid = GET_PROFILE_STATE._id;
      //   this.setState({msgcount: Cookies.get('newmsgs')});
      // } else {
      //   Cookies.set('newmsgs', 0);

      // }
      chatid = GET_PROFILE_STATE._id;
    } else if (Cookies.get('fmschatid')) {
      chatid = Cookies.get('fmschatid');
      // this.setState({msgcount: Cookies.get('newmsgs')});
    } else {
      const newid = uuidv4();
      Cookies.set('fmschatid', newid);
      // Cookies.set('newmsgs', 0);
      chatid = newid;
    }
    // console.log('CHAT ID', chatid);
    // Connect to the server
    // this.socket = io(config.api, { query: `username=${chatid}` }).connect();
    this.socket = io(config.api, {
      transports: ['websocket'],
      query: `username=${chatid}`,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
    }).connect();

    this.socket.on('private', ({username, message}) => {
      // console.log('PRIVATE MESSAGE RECEIVED', username, message);
      const msgcount = this.state.msgcount + 1;
      const msg = {
        message,
        username,
        room: Cookies.get('fmschatid'),
        recipient: Cookies.get('fmschatid'),
        date: Date(),
      };
      if (this.state.open === true) {
        this.addMessage(msg);
      } else {
        this.setState({newmessage: true, msgcount});
        toast.info(msg.message, {
          onClick: this.OpenMessages,
        });
        this.addMessage(msg);
        // Cookies.set('newmsgs', msgcount);
      }
    });

    this.socket.on('fmsonline', (isOnline) => {
      this.setState({live: isOnline});
    });

    if (typeof window !== 'undefined' && this.props.location.pathname) {
      this.emitUserDetails();
    }

    try {
      const response = await HTTP_REQUEST_CHAT(Cookies.get('fmschatid'));

      if (response && response.ok) {
        const res = await response.json();
        this.setState({messages: res});
      } else {
        // Handle non-200 responses
        // toast.error('There was an issue retrieving your messages.');
      }
    } catch (error) {
      console.error('Error during HTTP request:', error);
      // toast.error('There was an issue submitting your message.');
    }
  }

  componentDidUpdate(prevProps) {
    const {GET_PROFILE_STATE} = this.props;
    if (GET_PROFILE_STATE._id !== prevProps.GET_PROFILE_STATE._id) {
      if (GET_PROFILE_STATE._id && GET_PROFILE_STATE._id !== Cookies.get('fmschatid')) {
        Cookies.set('fmschatid', GET_PROFILE_STATE._id);
      }
    }
    if (
      typeof window !== 'undefined' &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      // this.emitUserDetails();
      if (
        typeof window !== 'undefined' &&
        this.props.location.pathname !== prevProps.location.pathname &&
        this.socket // Ensure socket exists before calling emitUserDetails
      ) {
        this.emitUserDetails();
      }
    }
  }

  handleClick(event) {
    this.setState({open: true, anchorEl: event.currentTarget});
  }

  handleClose() {
    this.setState({anchorEl: false});
  }

  AddStatus(status) {
    this.setState({
      status,
    });
  }

  emitUserDetails() {
    if (!this.socket) {
      console.warn("Socket is not initialized yet.");
      return;
    }
    if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
      const userDetails = {
        page: this.props.location.pathname,
        language: navigator.language || navigator.userLanguage,
      };

      // console.log('User details:', this.props.GET_PROFILE_STATE);
      // Add firstName if it is present
      if (this.props.GET_PROFILE_STATE && this.props.GET_PROFILE_STATE.firstName) {
        userDetails.firstName = this.props.GET_PROFILE_STATE.firstName;
        userDetails.lastName = this.props.GET_PROFILE_STATE.lastName;
        userDetails.email = this.props.GET_PROFILE_STATE.email;
        userDetails.businessName = this.props.GET_PROFILE_STATE.businessName;
        userDetails.subscription = this.props.GET_PROFILE_STATE.subscription;
      }

      this.socket.emit('userDetails', userDetails);
    }
  }

  async sendHandler(message) {
    const messageObject = {
      username: Cookies.get('fmschatid'),
      message,
    };

    const payload = {
      username: Cookies.get('fmschatid'),
      room: Cookies.get('fmschatid'),
      message,
      recipient: 'findmesales',
      date: Date(),
      read: true,
    };
    // Emit the message to the server

    await HTTP_ADD_CHAT(payload)
      .then((response) => response.json())
      .then((response) => {
        // this.socket.emit('client:message', messageObject);
        // this.socket.emit('private message', {
        //   content: messageObject,
        //   to: 'Find Me Sales',
        // });
        this.socket.emit('private', {
          content: messageObject,
          to: 'findmesales',
        });
        messageObject.fromMe = true;
        this.addMessage(messageObject);
      })
      .catch((error) => {
        // handle error
        // toast.error('There was an issue submitting your message.');
        console.log(error);
      });
  }

  // addMessage(message) {
  //   // Append the message to the component state
  //   const {messages} = this.state;
  //   messages.push(message);
  //   this.setState({messages});
  // }
  addMessage(message) {
    // Append the message to the component state in an immutable way
    this.setState((prevState) => ({
      messages: [...prevState.messages, message],
    }));
  }

  OpenMessages() {
    toast.dismiss();
    this.setState({newmessage: false, msgcount: 0, open: true});
  }

  async HandleSubmit(event) {
    event.preventDefault();
    const payload = {
      email: this.state.email,
      name: this.state.name,
      message: this.state.message,
    };
    const response = await HTTP_SUBMIT_MESSAGE(payload);
    if (response && response.status === 200) {
      toast.success('Thank you for submitting your message');
      this.setState({open: false});
    } else {
      toast.error(
        'There was an issue submitting your message. Please refresh the page and try again.'
      );
    }
  }

  render() {
    const {navigate} = this.props;
    if (this.props.location.pathname === '/start-free') {
      return null;
    } else 
    if (this.state.open === false) {
      if (this.state.newmessage === false) {
        return (
          <div>
            <Fab
              aria-label="message"
              color="primary"
              style={{
                color: '#ffffff',
                position: 'fixed',
                bottom: '14px',
                right: '18px',
                zIndex: '5000',
              }}
              onClick={this.OpenMessages}>
              <ChatIcon
                style={{
                  transform: 'scaleX(-1)',
                  MozTransform: 'scaleX(-1)',
                  WebkitTransform: 'scaleX(-1)',
                  msTransform: 'scaleX(-1)',
                }}
              />
            </Fab>
          </div>
        );
      } else {
        return (
          <div>
            <Grid item xs={3} style={{padding: '16px 4px 8px 16px'}}>
              {/* <Badge
                badgeContent={this.state.msgcount}
                // color="linkedin"
                showZero
                // style={{fontWeight: '90px'}}
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}> */}

              <Fab
                aria-label="message"
                color="primary"
                style={{
                  color: '#ffffff',
                  position: 'fixed',
                  bottom: '14px',
                  right: '18px',
                  zIndex: '5000',
                }}
                onClick={this.OpenMessages}>
                <Badge
                  color="secondary"
                  overlap="circular"
                  style={{
                    position: 'fixed',
                    bottom: '22px',
                    right: '26px',
                  }}
                  badgeContent={this.state.msgcount}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}>
                  <img
                    src="https://storage.googleapis.com/find-me-sales-bucket/assets/admin/team/willheadshot.png"
                    alt="Will Headshot"
                    loading="lazy"
                    width="322"
                    height="322"
                    style={{
                      maxHeight: '56px',
                      maxWidth: '56px',
                      animation: 'pulse 2s infinite',
                      // boxShadow:
                      //   '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
                      border: '1px solid #ffffff',
                      borderRadius: '50px',
                      position: 'fixed',
                      bottom: '14px',
                      right: '18px',
                    }}
                  />
                </Badge>
              </Fab>
            </Grid>
            {/* <Fab
              aria-label="message"
              color="primary"
              style={{color: '#ffffff', position: 'fixed', bottom: '14px', right: '18px'}}
              onClick={() => this.setState({newmessage: false, open: true})}>
              <ChatIcon
                style={{
                  transform: 'scaleX(-1)',
                  MozTransform: 'scaleX(-1)',
                  WebkitTransform: 'scaleX(-1)',
                  msTransform: 'scaleX(-1)',
                }}
              />
            </Fab> */}
          </div>
        );
      }
    } else if (this.state.live === true) {
      return (
        <div>
          <Container
            style={{
              position: 'fixed',
              bottom: '88px',
              right: '0px',
              width: '328px',
              zIndex: '99999',
            }}>
            <Grid>
              <Paper style={{height: '450px'}}>
                <ChatApp
                  username={Cookies.get('fmschatid')}
                  sendHandler={this.sendHandler}
                  addMessage={this.addMessage}
                  messages={this.state.messages}
                />
              </Paper>
            </Grid>
          </Container>
          <Fab
            aria-label="message"
            color="primary"
            style={{
              color: '#ffffff',
              position: 'fixed',
              bottom: '14px',
              right: '18px',
            }}
            onClick={() => this.setState({open: false})}>
            <ClearIcon />
          </Fab>
        </div>
        //   <p
        //   style={{
        //     maxWidth: '45%',
        //     padding: '14px',
        //     margin: '14px',
        //     textAlign: 'left',
        //     borderStyle: 'solid',
        //     borderColor: '#003671',
        //     borderWidth: 'thin',
        //     backgroundColor: '#ffffff',
        //     // backgroundColor: '#003671',
        //     borderRadius: '15px 15px 0px 15px',
        //     position: 'fixed',
        //     zIndex: 0,
        //   }}>
        //   Hello!
        // </p>
      );
    } else {
      return (
        <div>
          <Container
            style={{
              position: 'fixed',
              bottom: '88px',
              right: '0px',
              width: '328px',
              zIndex: '99999',
            }}>
            <Grid>
              <Paper style={{height: '450px'}}>
                <Grid container xs={12}>
                  <Typography
                    variant="body1"
                    paragraph
                    align="justify"
                    style={{
                      backgroundColor: '#003671',
                      padding: '9px 14px',
                      color: '#ffffff',
                      marginBottom: '0px',
                      borderRadius: '4px 4px 0px 0px',
                    }}>
                    Drop us a message and we'll answer you soon.
                  </Typography>
                </Grid>
                <ValidatorForm
                  id="messageform"
                  style={{padding: '16px'}}
                  onError={(errors) => {
                    toast.error(errors);
                  }}
                  onSubmit={this.HandleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{height: '80px'}}>
                      <TextValidator
                        variant="outlined"
                        // required
                        fullWidth
                        onChange={(e) => this.setState({email: e.target.value})}
                        id="email"
                        label="Email Address"
                        name="email"
                        value={this.state.email}
                        autoFocus
                        autoComplete="email"
                        validators={['required', 'isEmail']}
                        errorMessages={['This field is required', 'Email is not valid']}
                      />
                    </Grid>
                    <Grid item xs={12} style={{height: '80px'}}>
                      <TextValidator
                        variant="outlined"
                        // required
                        fullWidth
                        onChange={(e) => this.setState({name: e.target.value})}
                        name="yourname"
                        label="Your Name"
                        id="yourname"
                        value={this.state.name}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    </Grid>
                    <Grid item xs={12} style={{height: '156px'}}>
                      <TextValidator
                        variant="outlined"
                        // required
                        fullWidth
                        onChange={(e) => this.setState({message: e.target.value})}
                        name="yourmessage"
                        label="Your Message"
                        id="yourmessage"
                        multiline
                        rows={5}
                        value={this.state.message}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        form="messageform"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="loginsubmit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Paper>
            </Grid>
          </Container>
          <Fab
            aria-label="message"
            color="primary"
            style={{
              color: '#ffffff',
              position: 'fixed',
              bottom: '14px',
              right: '18px',
            }}
            onClick={() => this.setState({open: false})}>
            <ClearIcon />
          </Fab>
        </div>
      );
    }
  }
}

Messenger.defaultProps = {
  DISPATCH_LOGOUT_ACTION: PropTypes.any,
  navigate: PropTypes.any,
};

Messenger.propTypes = {
  // GET_AUTH_STATE: PropTypes.object.isRequired,
  // GET_PROFILE_STATE: PropTypes.object.isRequired,
  // GET_ERRORS_STATE: PropTypes.array.isRequired,
  // DISPATCH_LOGOUT_ACTION: PropTypes.any,
  // getAuth: PropTypes.func.isRequired,
  // getProfile: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Messenger));
