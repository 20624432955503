import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slider,
  Typography,
  Card,
  CardContent,
  CardActions,
  Box,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import CreditSlider from 'app/components/CreditSlider';

// import { checkoutBoltOnCredits } from "app/components/stripe.service";

const discountMap = {
  Free: 0,
  Starter: 24,
  Pro: 33,
  Enterprise: 43,
};

const basePricePerCredit = 0.21; // Base price from free tier

function CreditPurchaseModal({isOpen, onClose, userPlan}) {
  const [credits, setCredits] = useState(100);
  const theme = useTheme();

  const userDiscount = discountMap[userPlan] || 0;
  const totalPrice = (credits * basePricePerCredit * (1 - userDiscount / 100)).toFixed(2);

  const handlePurchase = async () => {
    const success = await checkoutBoltOnCredits(credits, totalPrice);
    if (success) onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '24px', // Ensures the dialog itself has a rounded border
          boxShadow: '0 8px 60px rgba(0, 0, 0, 0.15)', // Matches the card shadow style
        },
      }}>
      <CardContent sx={{textAlign: 'center'}}>
        <DialogTitle sx={{fontWeight: '600', fontSize: '1.5rem', pb: 1}}>
          Buy Credits
        </DialogTitle>

        {/* Embed the Credit Slider inside the modal */}
        <CreditSlider userPlan={userPlan} onPurchase={handlePurchase} showBuyButton />
      </CardContent>
    </Dialog>
  );
}

CreditPurchaseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userPlan: PropTypes.string.isRequired,
};

export default CreditPurchaseModal;
