import {toast} from 'react-toastify';
import debounce from 'lodash.debounce';

const debounceMap = new Map();

// async function actualFetch(url, options) {
//   try {
//     const response = await fetch(url, options);

//     const res = response;

//     if (res.status === 429) {
//       toast.error('Rate limit exceeded. Please wait before trying again.');
//       return null;
//     }

//     if (res.status === 401) {
//       toast.error('Unauthorised. Please check your credentials.');
//       return null;
//     }

//     if (process.env.NODE_ENV === 'development' && res.status === 504) {
//       toast.error('Connection timeout. Please try again later.');
//       return null;
//     }

//     if (res.headers.get('Content-Type') === 'application/json') {
//       return await response;
//     }
  
//     return response;
//   } catch (error) {
//     console.error('Error during fetch:', error);
//     if (process.env.NODE_ENV === 'development') {
//       toast.error('An error occurred. Please try again.');
//     }
//     throw error;
//   }
// }
// async function actualFetch(url, options) {
//   try {
//     const response = await fetch(url, options);

//     // if (!response.ok) {
//     //   // Handle HTTP errors
//     //   // toast.error(`Error: ${response.status}`);
//     //   return response;
//     // }

//     // Return the raw response for the caller to handle
//     return response;
//   } catch (error) {
//     console.error('Error during fetch:', error);
//     // toast.error('An error occurred. Please try again.');
//     throw error;
//   }
// }
// async function actualFetch(url, options) {
//   try {
//     const response = await fetch(url, options);
//     // console.log('actualFetch response', response.clone());
//     return response;
//   } catch (error) {
//     console.error('Error during fetch:', error);
//   }
// }
async function actualFetch(url, options) {
  const apiKey = process.env.REACT_APP_FETCH_CONNECT;

  if (!apiKey) {
    console.error('API key is not defined');
    throw new Error('API key is not defined');
  }

  const headers = {
    ...options.headers,
    'X-API-KEY': apiKey,
  };

  try {
    const response = await fetch(url, { ...options, headers });
    return response;
  } catch (error) {
    console.error('Error during fetch:', error);
    throw error;
  }
}
// async function actualFetch(url, options) {
//   // const token = localStorage.getItem('token'); // Assuming you store the JWT in localStorage
//   const apiKey = process.env.REACT_APP_FETCH_CONNECT;
//   // console.log('URL:', url, 'Options:', options, apiKey, process.env.REACT_APP_FETCH_CONNECT);

//   const headers = {
//     ...options.headers,
//     // 'Authorization': `Bearer ${token}`,
//     'X-API-KEY': apiKey,
//   };

//   // try {
//   //   const response = await fetch(url, { ...options, headers });
//   //   return response;
//   // } catch (error) {
//   //   console.error('Error during fetch:', error);
//   // }
//   try {
//     const response = await fetch(url, { ...options, headers });
//     // if (!response.ok) {
//     //   console.log('Network response was not ok', response);
//     // }
//     return response;
//   } catch (error) {
//     console.error('Error during fetch:', error);
//   }
// }

export async function customFetch(url, options) {
  // console.log('customFetch', url, options);
  try {
    const result = await actualFetch(url, options);
    return result;
  } catch (error) {
    console.error('Error in customFetch:', error);
  }
}

// export function customFetch(url, options) {
//   if (!debounceMap.has(url)) {
//     const debouncedFetch = debounce(async (options, resolve, reject) => {
//       try {
//         const result = await actualFetch(url, options);
//         resolve(result);
//       } catch (error) {
//         reject(error);
//       }
//     }, 200);

//     debounceMap.set(url, {
//       debouncedFetch,
//       currentPromise: null,
//     });
//   }

//   const {debouncedFetch, currentPromise} = debounceMap.get(url);

//   if (currentPromise) {
//     return currentPromise;
//   }

//   const newPromise = new Promise((resolve, reject) => {
//     debouncedFetch(options, resolve, reject);
//   });

//   newPromise.finally(() => {
//     debounceMap.set(url, {
//       debouncedFetch,
//       currentPromise: null,
//     });
//   });

//   debounceMap.set(url, {
//     debouncedFetch,
//     currentPromise: newPromise,
//   });

//   return newPromise;
// }

